<template>
  <div>
    <div class="hold-transition">
      <loading v-if="cargando" />
      <div
        class="modal fade"
        id="modal-form-roles-export"
        style="background-color: #00000082"
      >
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <h4 class="modal-title text-white">Generar Listado Roles</h4>
              <button
                type="button"
                class="close text-white"
                data-dismiss="modal"
                aria-label="Close"
                id="close-modal"
                ref="closeModal1"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Columnas"
                    data-toggle="tab"
                    href="#Columnas"
                    >Columnas</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Filtros"
                    data-toggle="tab"
                    href="#Filtros"
                    >Filtros
                  </a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane" id="Filtros">
                  <div class="card-body">
                    <div class="form-group row">
                      <label for="name" class="col-md-5">Nombre</label>
                      <input
                        type="text"
                        id="name"
                        v-model="filtros.name"
                        class="col-md-7"
                      />
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade active show" id="Columnas">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-11">
                        <div class="form-group">
                          <div>
                            <div class="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                id="name"
                                v-model="form.name"
                                class="custom-control-input"
                              />
                              <label class="custom-control-label" for="name"
                                >Nombre</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal-footer justify-content-between">
              <div>
                <button
                  type="button"
                  class="btn btn-sm bg-navy"
                  @click="generarListadoExcel()"
                  v-if="$store.getters.can('admin.roles.export')"
                >
                  Generar Listado
                  <i class="fas fa-file-download"> </i>
                </button>
              </div>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
//import vSelect from "vue-select";
export default {
  name: "RolesExport", //llegada tambien
  components: { Loading, },
  data() {
    return {
      cargando: false,
      form: {
        name: true,
      },
      filtros: {
        name: null,
      },

    };
  },

  methods: {
    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/roles/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          this.cargando = false;
          let data = response.data;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {    
  },
};
</script>
