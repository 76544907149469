import { render, staticRenderFns } from "./RolesIndex.vue?vue&type=template&id=4c4cdfc8&"
import script from "./RolesIndex.vue?vue&type=script&lang=js&"
export * from "./RolesIndex.vue?vue&type=script&lang=js&"
import style0 from "./RolesIndex.vue?vue&type=style&index=0&id=4c4cdfc8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports